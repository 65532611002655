<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Products</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="product.name"
                            hide-details
                            prepend-icon="mdi-text-short"
                            label="Name"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="product.abbreviation"
                            hide-details
                            prepend-icon="mdi-barcode"
                            label="Abbreviation"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveProduct"
                rounded
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'ProcessForm',
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        originalProduct: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        productsSettings: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        rules: {
            required: v => !!v || 'Required',
        },
    }),
    computed: {
        productDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalProduct, this.product)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveProduct() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                const product = await API.createProduct({
                    settingId: this.productsSettings.id,
                    ...this.product,
                })
                this.$emit('addProduct', product)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const product = await API.updateProduct({
                    settingId: this.productsSettings.id,
                    productId: this.product.id,
                    ...this.productDiff,
                })
                this.$emit('replaceProduct', product)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
